<template>
  <div class="routeMonitor">
    <RouteMonitorNav></RouteMonitorNav>
    <div style="height: 100%">
      <router-view />
    </div>
    <information-footer :image-src="imgUrl" image-height="20px" image-width="auto" email="dev@cgtf.net" />
  </div>
</template>

<script>
import imgUrl from '@/assets/images/Dragonlab_FlowWatch-white.svg'
// import Foot from '@/submodules/components/Footer'
import RouteMonitorNav from '@/components/layout/RouteMonitorNav'
export default {
  name: 'Home',
  components: {
    // Foot,
    RouteMonitorNav,
  },
  mounted() {},
  data() {
    return {
      imgUrl,
    }
  },
}
</script>

<style lang="scss" scoped>
.routeMonitor {
  background-image: linear-gradient(45deg, #efbdfa, #cbe3fd, #f9d9d9);
  
}
</style>
