<template>
  <div class="navbar">
    <el-menu
      router
      mode="horizontal"
      background-color="#ffffff00"
      text-color="#000000"
      active-text-color="#631fa9"
      :default-active="`/${$route.path.split('/')[1]}`"
    >
      <el-menu-item style="width: 250px">
        <img src="@/assets/images/Dragonlab_FlowWatch.svg" width="100%" height="100%" />
      </el-menu-item>
      <el-menu-item
        v-for="(item, i) in navList"
        :key="i"
        :index="item.path"
        :style="{ display: isVisible(item) ? 'list-item' : 'none' }"
      >
        <span>{{ isVisible(item) }}</span>
      </el-menu-item>
      <el-menu-item style="float: right">
        <span v-if="!userInfo" @click="login">Login</span>
        <el-popover v-else trigger="click">
          <div class="user-info">
            <div class="profile margin-bottom">
              <img src="@/assets/images/user_profile.svg" width="30%" />
            </div>
            <span class="userName margin-bottom">{{ userInfo.user_name }}</span>
            <div>
              <el-button class="exit user-info-buttom" @click="updateUser"> Information</el-button>
              <el-button class="exit user-info-buttom" @click="signOut">Sign out</el-button>
            </div>
          </div>
          <el-button slot="reference" class="user-info-buttom" icon="el-icon-user-solid">
            {{ userInfo.user_name }}</el-button
          >
        </el-popover>
      </el-menu-item>

      <el-menu-item style="float: right; width: 100px" class="version-selector">
        <el-select
          v-model="IpVersion"
          placeholder="请选择"
          style="width: 100px"
          popper-class="version-option"
          @change="changeVersion"
        >
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-menu-item>
    </el-menu>
    <Dialog
      title="Personal Information"
      :visible.sync="UpdaterVisible"
      :sub-component="Information"
      width="600px"
      :showFooter="false"
      @close_diolog="UpdaterVisible = false"
    >
    </Dialog>
    <Dialog
      title="Login"
      :visible.sync="LoginVisible"
      :sub-component="Login"
      width="600px"
      :showFooter="false"
      @close_diolog="LoginVisible = false"
    >
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog'
import Login from '@/components/Login'
import Information from '@/components/Information'
export default {
  name: 'RouteNav',
  created() {},
  inject: ['reload'],
  watch: {},
  components: {
    Dialog,
  },
  data() {
    return {
      IpVersion: localStorage.getItem('ip_version') || 'netFlow', //true v4 false v6
      LoginVisible: false,
      UpdaterVisible: false,
      Login,
      Information,
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      options: [
        // {
        //   value: 'v4',
        //   label: 'v4',
        // },
        // {
        //   value: 'v6',
        //   label: 'v6',
        // },
        {
          value: 'nf',
          label: 'netFlow',
        },
      ],
      navList: [
        { path: '/top', navItem: 'Home', needLogin: false },
        { path: '/overview', navItem: 'Overview', needLogin: false },
        { path: '/anomaly', navItem: 'One-Way', needLogin: false },
        { path: '/app_view', navItem: 'Application', needLogin: false },
        { path: '/client_view', navItem: 'IP', needLogin: false },
        { path: '/flow_view', navItem: 'Flow', needLogin: false },
        { path: '/users', navItem: 'Users', needLogin: false, admin: true },
        // { path: '/remote', navItem: 'Remote', needLogin: false },
        { path: '/document/manual', navItem: 'Manual', needLogin: false },
      ],
    }
  },
  mounted() {},
  props: {},
  methods: {
    isVisible(navItem) {
      let result = navItem.navItem
      if (navItem.needLogin) {
        if (this.userInfo) {
          result = navItem.navItem
        } else {
          result = ''
        }
      } else {
        result = navItem.navItem
      }

      if (navItem.admin) {
        if (this.userInfo && this.userInfo.admin) {
          result = navItem.navItem
        } else {
          result = ''
        }
      }
      return result
    },
    signOut() {
      localStorage.removeItem('userInfo')
      this.reload()
    },
    updateUser() {
      this.UpdaterVisible = true
    },
    login() {
      this.LoginVisible = true
    },
    changeVersion(value) {
      // console.debug('change', value)
      localStorage.setItem('ip_version', value)
      this.reload()
    },
    selectNav(nav) {
      // /v4/overview/
      // console.debug('this.$route.path', this.$route.path)
      this.$router.push(nav)
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 72px;
  overflow: hidden;
  position: relative;
  .el-menu-item {
    height: 100%;
    line-height: 72px;
    font-weight: 500;
    font-size: var(--middle-font-size);
  }
  .svg-container img {
    margin-bottom: 30px;
  }
  .el-menu-item:hover {
    outline: 0 !important;
    color: #631fa9 !important;
    background: #00000000 !important;
  }
  .el-menu-item.is-active {
    border-bottom: 0px;
    color: #631fa9 !important;
    background: #00000000 !important;
  }
  .el-menu-item.is-active span {
    border-bottom: 2px solid #631fa9;
    padding-bottom: 10px;
  }
}

.user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.profile {
  text-align: center;
}

.margin-bottom {
  margin-bottom: 10px;
}

.userName {
  font-weight: bold;
}
.user-info-buttom,
.user-info-buttom i {
  background-color: #f0f0f000;
  color: #6718a9;
  border-color: #6718a900;
}

.user-info-buttom:focus,
.user-info-buttom:hover {
  color: #6718a9;
  border-color: #855da500;
  background-color: #fcf7ff00;
}

.version-option .el-select-dropdown__item.selected {
  color: #631fa9;
}
</style>
