<template>
  <el-form :model="LoginForm" status-icon class="login-form" label-width="100px">
    <el-form-item label="UserName:" prop="userName" style="font-weight: bold">
      <el-input
        v-model.number="LoginForm.userName"
        placeholder="Please input username"
        class="login-input"
      ></el-input>
    </el-form-item>
    <el-form-item label="Password:" prop="password" style="font-weight: bold">
      <el-input
        type="password"
        v-model="LoginForm.password"
        placeholder="Please input password"
        show-password
        class="login-input"
      ></el-input>
    </el-form-item>
    <el-button :loading="loading" class="login-submit" @click="login">Login</el-button>
  </el-form>
</template>

<script>
import { login } from '@/api/UserApi'
import { Message } from 'element-ui'
export default {
  name: 'Login',
  created() {},
  data() {
    return {
      loading: false,
      LoginForm: {
        userName: '',
        password: '',
      },
    }
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    param: {
      type: Object,
      default: () => {},
    },
  },
  inject: ['reload'],
  methods: {
    async login() {
      this.loading = true
      const { status, data } = await login(
        this.LoginForm.userName,
        this.LoginForm.password
      )
      this.loading = false
      if (status !== 'failed') {
        localStorage.setItem('userInfo', JSON.stringify(data))
        this.reload()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.login-input {
  width: 300px;
}

.login-form {
  width: 400px;
  text-align: center;
}

.login-submit {
  width: 400px;
  background-color: #4b127d;
  border-color: #4b127d;
  color: #fff;
  margin-bottom: 50px;
}
</style>
