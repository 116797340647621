<template>
  <el-form
    ref="RegisterForm"
    :rules="rules"
    :model="RegisterForm"
    status-icon
    class="login-form"
    label-width="150px"
  >
    <el-form-item label="UserName:" style="font-weight: bold">
      <el-input
        v-model.number="RegisterForm.userName"
        placeholder="Please input username"
        class="login-input"
        disabled
      ></el-input>
    </el-form-item>
    <el-form-item label="Password:" prop="password" style="font-weight: bold">
      <el-input
        type="password"
        v-model="RegisterForm.password"
        placeholder="Please input password"
        show-password
        class="login-input"
      ></el-input>
    </el-form-item>
    <el-form-item label="New password:" prop="new_password" style="font-weight: bold">
      <el-input
        type="password"
        v-model="RegisterForm.new_password"
        placeholder="Please input password again"
        show-password
        class="login-input"
      ></el-input>
    </el-form-item>
    <el-form-item label="Email:" style="font-weight: bold">
      <el-input
        v-model.number="RegisterForm.email"
        placeholder="Please input email"
        class="login-input"
        type="email"
      ></el-input>
    </el-form-item>
    <el-button :loading="loading" class="login-submit" @click="update">Update</el-button>
  </el-form>
</template>

<script>
import { update } from '@/api/UserApi'
import { Message } from 'element-ui'
export default {
  name: 'Information',
  created() {},
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
      loading: false,
      rules: {
        password: [{ required: true, message: 'Please input password', trigger: 'blur' }],
        new_password: [{ message: 'Please input password again', trigger: 'blur' }],
      },
      RegisterForm: {
        userName: '',
        password: '',
        new_password: '',
        email: '',
      },
    }
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    param: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.RegisterForm.userName = this.userInfo.user_name
    this.RegisterForm.email = this.userInfo.email
  },
  inject: ['reload'],
  methods: {
    async update() {
      try {
        await this.$refs.RegisterForm.validate()
        this.loading = true
        const { status, data } = await update(
          this.userInfo.user_id,
          this.RegisterForm.password,
          this.RegisterForm.new_password,
          this.RegisterForm.email
        )
        this.loading = false
        if (status !== 'failed') {
          Message({
            message: 'Update Success',
            type: 'success',
            duration: 5 * 1000,
          })
          this.userInfo.email = this.RegisterForm.email
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.reload()
        }
      } catch (e) {}
    },
  },
}
</script>

<style lang="scss" scoped>
.login-input {
  width: 300px;
}

.login-form {
  width: 450px;
  text-align: center;
}

.login-submit {
  width: 450px;
  background-color: #4b127d;
  border-color: #4b127d;
  color: #fff;
  margin-bottom: 50px;
}
</style>
